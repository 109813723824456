@charset "UTF-8";

@import "normalize";
@import "animate";

@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

$text: #333;
$main: #1b2d53;
$accent: #c19b5c;
$sub: #5b1616;

@mixin fontsize($size: 24, $base: 10) {
  font-size: $size + px;
  font-size: ($size / $base) * 1rem;
}
@mixin max{
  max-width: 1320px;
  @media(min-width: 1320px){
    margin: 0 auto;
  }
}
@mixin max2{
  max-width: 1080px;
  @media(min-width: 1080px){
    margin: 0 auto;
  }
}
.gold{
  color: $accent!important;
  a{
    color: $accent!important;
    span{
      color: $accent!important;
    }
  }
}
.mini{
  font-size: 1.6rem;
}
.wrap{
  @include max;
}
.mbl{
  @media(min-width:550px){
    display: none;
  }
}
.sp{
  @media(min-width:750px){
    display: none;
  }
}
.tb{
  @media(min-width:1250px){
    display: none;
  }
}
.pc2{
  @media(max-width:449px){
    display: none !important;
  }
}
.pc{
  @media(max-width:749px){
    display: none !important;
  }
}
defs{
  display: none;
}

* {-webkit-box-sizing: border-box;
 -moz-box-sizing: border-box;
 box-sizing: border-box
}
body {
    position: relative;
    @include fontsize(16);
    font-family: 'Noto Sans Japanese', sans-serif;
    color: $text;
    position: relative;
    text-align: justify;
    text-justify: inter-ideograph;
    line-height: 2;
}
//ローディング画面
#loader {
    position: fixed;
    display: table;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background: $main;
    z-index: 101;
    -webkit-transition: 1s;
    transition: 1s;
    transform-origin: top;
    -webkit-animation: fadeout 3s ease 1s 1 forwards;
          animation: fadeout 3s ease 1s 1 forwards;
    &__img {
        display: table-cell;
        vertical-align: middle;
        margin: 0 auto;
        text-align: center;
        p{
          color: $text;
        }
    }
    
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}
.loader{
//	border: solid;  /*to test position*/
  width: 230px;
  height: 20px;
  position: absolute;
  top: 45%;
  left: 45%;
  margin: -10px -85px;
  
}

.loader div{
  height: 20px;
  width: 20px;
  //background: #fff;
  color: $accent;
  border-radius: 45%;
  position: absolute;
  -webkit-animation: animate 1.4s ease infinite;
}


.loader div:nth-child(2) {
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -webkit-animation-delay: .08s;
}

.loader div:nth-child(3) {
  transform: translateX(60px);
  -webkit-transform: translateX(60px);
  -webkit-animation-delay: .16s;
}

.loader div:nth-child(4) {
  transform:translateX(90px);
  -webkit-transform: translateX(90px);
  -webkit-animation-delay: .24s;
}

.loader div:nth-child(5) {
  transform: translateX(120px);
  -webkit-transform: translateX(120px);
  -webkit-animation-delay: .32s;
}

.loader div:nth-child(6) {
  transform: translateX(150px);
  -webkit-transform: translateX(150px);
  -webkit-animation-delay: .40s;
}
.loader div:nth-child(7) {
  transform: translateX(180px);
  -webkit-transform: translateX(180px);
  -webkit-animation-delay: .48s;
}
.loader div:nth-child(8) {
  transform: translateX(210px);
  -webkit-transform: translateX(210px);
  -webkit-animation-delay: .56s;
}

@-webkit-keyframes animate {
  0%{left: 0px; top: 0px;}
  5%{left: -2px; top: 4px; width: 24px; height: 16px; -webkit-filter: blur(0.1px);}
  10%{left: -1px; top: 4px; width: 22px; height: 18px; -webkit-filter: blur(0.1px);} 
  /*halfway up*/
  15%{left: 1px; top: -17px; width: 18px; height: 22px; -webkit-filter: blur(0.3px);} 
  /*top*/
  22%{left: 0px; top: -34px; width: 20px; height: 20px; -webkit-filter: blur(0.2px);} 
  /*halfway down*/
  29%{left: 1px; top: -17px; width: 18px; height: 22px; -webkit-filter: blur(0.3px);} 
  /*bottom*/
  34%{left: 1px; top: 0px;  width: 18px; height: 22px; -webkit-filter: blur(0.2px);}
  /*elastic bottom*/
  40%{left: -1px; top: 4px; width: 22px; height: 18px; -webkit-filter: blur(0.1px);} 
  /*end*/
  43%{left: 0px; top: 0px; width: 20px; height: 20px;-webkit-filter: blur(0px)} 
  100%{left: 0px; top: 0px;}
}

//バックボタン
.back{
  position: fixed;
  bottom: 60px;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: $accent;
  -webkit-box-shadow: 0 1px 1px rgba($text,.8);
          box-shadow: 0 1px 1px rgba($text,.8);
  -webkit-transition: .5s;
  transition: .5s;
  z-index: 1;
  &__arrow{
    width: 20px;
    height: 20px;
    border: 1px solid;
    border-color: transparent transparent #fff #fff;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    position: absolute;
    bottom: 9px;
    left: 14px;
  }
}

//メニュー画面
.menu_area{
  position: fixed;
  top: 60px;
  left: 0;
  background: #fff;
  width: 230px;
  height: 100vh;
  z-index: -1;
  ul{
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    text-align: left;
    padding: 20px 10px;
    li{
      @include fontsize(24);
      font-weight: bold;
      display: block;
      padding-right: 10px;
      border-bottom: 2px solid #fff;
      a{
        color: #fff;
        -webkit-transition: .5s;
        transition: .5s;
        span{
          display: block;
          @include fontsize(16);
          color: $text;
        }
      }
      .current{
        color: #ff5851;
      }
      @media(max-height: 439px){
        @include fontsize(9);
      }
    }
  }
}
.menu_open{
  z-index: 2;
  ul li{
    font-family: Georgia, 'Hiragino Mincho ProN', 'Yu Mincho', serif;
    line-height: 22px;
    padding: 10px 0;
    a{
      color: $main;
    }
  }
}
#container{
  top: 0;
  right: 0;
  width: 100%;
  background: #fff;
  -webkit-transition: 1s;
  transition: 1s;
}
#toggleMenu{
  -webkit-transition: 1.5s;
  transition: 1.5s;
}
.overlay{
  position: fixed;
  top: 0;
  right: -230px;
  background: rgba($main,.5);
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
.fixbody{
  overflow: hidden !important;
}
.fix-menu{
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  background: rgba(#000,.7);
  box-sizing: border-box;
  padding: 10px 10px;
  margin: 0;
  width: 100%;
  transform-origin: bottom;
  transition: .5s;
  z-index: 1;
  &__bar {
    background: $accent;
    list-style: none;
    width: 46%;
    font-size: 3vw;
    text-align: center;
    padding: 5px 0;
    a{
      font-family: "Yu Mincho", "YuMincho", serif;
      color: #fff;
    }
  }
  @media(min-width: 750px){
    display: none;
  }
}
main {
    display: block;
    -webkit-transition: .5s;
    transition: .5s;
    min-height: calc(100vh - 100px);
    overflow-x: hidden;
}
//ヘッダー
header{
  position: relative;
  //position: fixed;
  width: 100%;
  height: auto;
  overflow: hidden;
  background: #fff;
  z-index: 2;
  top: 0;
  left: 0;
  transition: .5s;
  transform-origin: top left;
}
.top{
  @include max;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  align-items: flex-start;
  padding-top: 10px;
  &__logo{
      display: block;
      box-sizing: border-box;
      svg{
        height: 60px;
        width: 80px;
        fill: $main;
        transition: .5s;
      }
      &:hover{
        svg{
          fill: $accent;
        }
      }
  }
  &__tel{
    display: inline-flex;
    flex-wrap: wrap;
    width: 70px;
    justify-content: center;
    margin-top: 5px;
    color: $main;
    svg{
      height: 32px;
      width: 32px;
      fill: $main;
    }
    span{
      font-family: "Yu Mincho", "YuMincho", serif;
      font-weight: bold;
      display: block;
      font-size: 1.3rem;
    }
  }
  &__menu{
    width: 680px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    li{
      list-style: none;
      display: inline-block;
    }
  }
  @media(min-width: 750px){
    align-items: center;
    padding: 0 10px;
    height: 90px;
    &__logo{
      margin-right: 20px;
      svg{
        margin-top: 10px;
        width: 105px;
        height: 80px;
      }
    }
    &__tel{
      display: none;
    }
    &__menu{
      &--bar{
        a{
          display: block;
          background: $accent;
          width: 160px;
          text-align: center;
          padding: 10px 0;
          color: #fff;
          font-weight: bold;
          font-family: "Yu Mincho", "YuMincho", serif;
          @include fontsize(14);
          transition: .5s;
          &:hover{
            background: $sub;
          }
        }
      }
      &--tel{
        @include fontsize(40);
        line-height: 1;
        font-family: "Yu Mincho", "YuMincho", serif;
        a{
          color: $main;
        }
        svg{
          height: 32px;
          width: 32px;
          fill: $main;
        }
      }
      &--time{
        text-align: right;
        display: block;
        @include fontsize(16);
      }
    }
  }
  @media(min-width: 950px){
    &__menu{
      width: 780px;
      &--bar a{
        width: 220px;
        padding: 10px;
        @include fontsize(18);
      }
    }
  }
}
.fix{
  position: fixed;
  box-shadow: 1px 1px 10px $text;
  background: #fff;
  @media(min-width: 750px){
    position: fixed;
    height: 40px;
    background: rgba(#000,.7);
    .top{
      align-items: flex-start;
      &__logo{
        svg{
          width: 60px;
          height: 45px;
          fill: $accent;
          margin: 0;
          &:hover{
            fill: $sub;
          }
        }
      }
      &__menu{
        &--bar{
          a{
            @include fontsize(15);
            line-height: 1;
            color: $text;
            width: 180px;
            &:hover{
              color: #fff;
            }
          }
        }
        &--tel{
          a{
            color: $accent;
          }
          svg{
            fill: $accent;
          }
        }
        &--time{
          display: none;
        }
      }
    }
  }
  @media(min-width:950px){
    .top__menu--bar a{
      width: 200px;
    }
  }
  @media(min-width:1100px){
    .top{
      &__logo{
        svg{
          width: 350px;
          height: 260px;
          margin-top: -180px;
        }
      }
    }
  }
}
.toggle{
  padding: 10px;
  //position: fixed;
  &__bar{
    position: relative;
    width: 50px;
    height: 30px;
    &--line{
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: $main;
      left: 0;
      -webkit-transition: .5s;
      transition: .5s;
      &:nth-child(1){
        top: 0;
      }
      &:nth-child(2){
        top: 13px;
      }
      &:nth-child(3){
        bottom: 0;
      }
    }
    &--name{
      font-weight: bold;
      font-family: "Yu Mincho", "YuMincho", serif;
      margin: 0;
      font-weight: bold;
      @include fontsize(13);
      position: absolute;
      bottom: -23px;
      left: 0;
      color: $main;
      width: 60px;
    }
  }
  @media(min-width: 750px){
    display: none;
  }
}
.close{
  .toggle__bar--line{
    &:nth-child(1){
      top: 13px;
      -webkit-transform: rotate(35deg);
              transform: rotate(35deg);
    }
    &:nth-child(2){
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    &:nth-child(3){
      bottom: 13px;
      -webkit-transform: rotate(-35deg);
              transform: rotate(-35deg);
    }
  }
}
.global{
  background: $main;
  &__menu{
    @include max;
    padding: 10px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    li{
      transform: scale(.8);
      line-height: 1.2;
      list-style: none;
      text-align: center;
      a{
        @include fontsize(20);
        color: #fff;
        font-weight: bold;
        font-family: Georgia, 'Hiragino Mincho ProN', 'Yu Mincho', serif;
        transition: .5s;
        span{
          @include fontsize(12);
          display: block;
        }
        &:hover{
          color: $accent;
        }
      } 
    }
    @media(min-width: 950px){
      padding: 20px 10px;
      li{transform: scale(1);}
    }
  }
}
.front{
  &__message{
    background: url(../img/topImage-sp.jpg) center center /cover no-repeat;
    color: #fff;
    @include fontsize(36);
    margin: 0;
    height: 70vw;
    display: inline-flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
    text-align: center;
    font-family: "Yu Mincho", "YuMincho", serif;
    @media(min-width: 750px){
      background: url(../img/topImage.jpg) center top /cover no-repeat;
      height: 33vw;
    }
  }
  &__logo {
    position: absolute;
    fill: rgba(#fff,.3);
    width: 75vw;
    height: 45vw;
    max-width: 500px;
    max-height: 250px;
  }
}
.fadeup{
  opacity: 0;
  -webkit-animation: fadeup 1s ease-in 1s 1 forwards;
          animation: fadeup 1s ease-in 1s 1 forwards;
}

@-webkit-keyframes fadeup {
  from {
    opacity: 0;
    -webkit-transform: translateY(50px);;
            transform: translateY(50px);;
  }

  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes fadeup {
  from {
    opacity: 0;
    -webkit-transform: translateY(50px);;
            transform: translateY(50px);;
  }

  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
.intro{
  @include max;
  &__title{
    &:first-letter{
      color: $accent;
    }
    text-align: center;
    @include fontsize(43);
    font-family: Georgia, 'Hiragino Mincho ProN', 'Yu Mincho', serif;
    line-height: 1.2;
    margin: 20px 0 0;
    &--gold{
      display: block;
      font-family: 'Noto Sans Japanese', sans-serif;
      color: $accent;
    }
    &--jp{
      display: block;
      @include fontsize(26);
      font-family: "Yu Mincho", "YuMincho", serif;
    }
  }
  &__caption{
    margin: 0 10px 5px;
  }
  @media(min-width: 750px){
    &__title{
      margin-left: 10px;
      text-align: left;
      span{
        display: inline;
        margin-left: 5px;
      }
    }
  }
}
.about{
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  li{
    width: 100%;
    position: relative;
    z-index: 0;
    display: block;
    width: 100%;
  }
  &__img{
    width: 100%;
    height: 59vw;
  }
  &__box{
    &--title{
      position: absolute;
      font-family: "Yu Mincho", "YuMincho", serif;
      top: 0;
      left: 0;
      background: rgba(#000,.7);
      color: #fff;
      margin: 0;
      height: 59vw;
      width: 36vw;
      font-size: 7.5vw;
      line-height: 1.3;
      padding: 10px 0 0 10px;
    }
    &--caption{
      margin: 0 10px ;
    }
    &--more{
      @include fontsize(24);
      color: $text;
      text-align: center;
      display: block;
      margin: 10px auto 30px;
      border: 1px solid $text;
      width: 70%;
      transition: .5s;
      &:hover{
        background: #333;
        color: #fff;
      }
    }
  }
  @media(min-width: 750px){
    li{
      width: calc(50% - 10px);
      &:first-child{
        margin-right: 20px;
      }
    }
    &__img{
      height: 29.5vw;
      max-height: 396px;
    }
    &__box{
      &--title{
        height: 29.5vw;
        width: 18vw;
        font-size: 4vw;
        line-height: 1.3;
        padding: 10px 0 0 10px;
      }
    }
  }
  @media(min-width: 1250px){
    &__box{
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(#000,.7);
      color: #fff;
      height: 29.5vw;
      max-height: 396px;
      width: 36%;
      &--title{
        position: static;
        background: transparent;
        @include fontsize(28);
        width: 100%;
        height: auto;
      }
      &--more{
        @include fontsize(20);
        color: #fff;
        text-align: center;
        display: block;
        margin: 10px auto 30px;
        border: 1px solid #fff;
        width: 70%;
        transition: .5s;
        &:hover{
          background: #FFF;
          color: $text;
        }
      }
    }
  }
}
.service{
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  li{
    display: block;
    width: 100%;
    height: 59vw;
    padding: 0px 10px;
    margin-bottom: 10px;
    a{
      font-family: "Yu Mincho", "YuMincho", serif;
      font-weight: bold;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      line-height: 1.2;
      font-size: 8vw;
      color: #fff;
      width: 100%;
      height: 59vw;
      position: relative;
      z-index: 0;
      overflow: hidden;
      transition: .5s;
      &:before{
        z-index: -2;
        width: 100%;
        height: 59vw;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        transition: 1s;
      }
      &:after{
        position: absolute;
        content: "詳細情報";
        color: transparent;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        background: rgba(#000,.3);
        width: 100%;
        height: 59vw;
        z-index: -1;
        transition: 1s;
      }
      &:hover{
        color: transparent;
        &:before{
          transform: scale(1.2);
        }
        &:after{
          @include fontsize(36);
          background: rgba(#fff,.5);
          color: $text;
        }
      }
    }
    &:first-child a{
      &:before{
        background: url(../img/serviceManagement@2x.jpg) center center /cover no-repeat;
        @media screen and (-webkit-min-device-pixel-ratio:2), (min-resolution: 2dppx) {
          background: url(../img/serviceManagement.jpg) center center /cover no-repeat;
        }
      }
    }
    &:nth-child(2) a{
      &:before{
        background: url(../img/serviceTravel@2x.jpg) center center /cover no-repeat;
        @media screen and (-webkit-min-device-pixel-ratio:2), (min-resolution: 2dppx) {
          background: url(../img/serviceTravel.jpg) center center /cover no-repeat;
        }
      }
    }
    &:last-child a{
      &:before{
        background: url(../img/serviceAgree@2x.jpg) center center /cover no-repeat;
        @media screen and (-webkit-min-device-pixel-ratio:2), (min-resolution: 2dppx) {
          background: url(../img/serviceAgree.jpg) center center /cover no-repeat;
        }
      }
    }
  }
  @media(min-width: 750px){
    margin: 0 10px;
    li{
      width: calc(100% / 3 - 10px);
      height: 20vw;
      max-height: 270px;
      padding: 0;
      a,
      a:before,
      a:after{
        width: 100%;
        height: 20vw;
        max-height: 270px;
      }
      a{
        @include fontsize(24);
      }
    }
  }
}
.contact-link{
  display: block;
  border-bottom: 10px solid #775215;
  text-align: center;
  background: $accent;
  font-family: "Yu Mincho", "YuMincho", serif;
  font-weight: bold;
  color: $text;
  margin: 20px 10px 30px;
  padding: 30px 0;
  font-size: 9vw;
  line-height: 1.2;
  position: relative;
  &:before{
    background: $text;
    position: absolute;
    content: "";
    width: calc(100% - 20px);
    height: 3px;
    top: 15px;
    left: 0;
    margin: 0 10px;
  }
  &:after{
    background: $text;
    position: absolute;
    content: "";
    width: calc(100% - 20px);
    height: 3px;
    bottom: 15px;
    left: 0;
    margin: 0 10px;
  }
  @media(min-width: 750px){
    @include fontsize(40);
    margin: 20px 0 0;
    padding: 50px 0;
    border: none;
    transition: .5s;
    &:before{
      top: 30px;
      left: 50%;
      transform: translate(-50% , 0);
      width: 750px;
      margin: 0;
    }
    &:after{
      bottom: 30px;
      left: 50%;
      transform: translate(-50% , 0);
      width: 750px;
      margin: 0;
    }
    &:hover{
      background: $text;
      color: $accent;
      &:before{
        background: $accent;
      }
      &:after{
        background: $accent;
      }
    }
  }
}
footer{
  background: $main;
  text-align: center;
  padding: 40px 0 15vw;
  color: #fff;
  a{
    color: #fff;
    transition: .5s;
    &:hover{
      color: $accent;
    }
  }
  @media(min-width: 750px){
    padding-bottom: 30px;
  }
}
.foot-menu{
  @include max;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__box{
    padding: 0;
    margin: 0;
    width: 100%;
    li{
      box-sizing: border-box;
      padding: 5px;
      display: block;
      width: 100%;
    }
  }
  @media(min-width: 750px){
    padding-bottom: 30px;
    &__box{
      width: calc(100% / 3);
      &:nth-child(2){
        box-sizing: border-box;
        border-left: 1px dashed #fff;
        border-right: 1px dashed #fff;
      }
    }
  }
}

//下層ページ
.low{
  &__box{
    width: 100%;
    height: 200px;
    display: inline-flex;
    align-items: center;
  }
  &__title{
    width: 100%;
    padding-left: 10px;
    margin: 0;
    color: #fff;
    line-height: 1.2;
    font-family: Georgia, 'Hiragino Mincho ProN', 'Yu Mincho', serif;
    @include fontsize(42);
    @include max;
    &:first-letter{
      color: $accent;
    }
    &--gold{
      color: $accent;
    }
    &--jp{
      display: block;
      font-family: "Yu Mincho", "YuMincho", serif;
      @include fontsize(24);
    }
  }
  &__intro{
    @include max;
    &--wrap{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      div{
        position: relative;
        z-index: 1;
        &:last-child{
          position: absolute;
          top: 0;
          left: -100px;
          z-index: 0;
          img{
            width: 120%;
            opacity: .2;
          }
        }
      }
    }
    &--title{
      text-align: center;
      margin: 0;
      font-family: "Yu Mincho", "YuMincho", serif;
      background: rgba(#fff,.8);
      width: 100%;
      line-height: 1.5;
      padding: 10px 0;
    }
    &--caption{
      margin: 0 10px 10px;
      color: #000;
    }
    &--link {
      display: block;
      margin: 20px auto;
      padding: 10px 0;
      width: 90%;
      color: $text;
      font-weight: bold;
      border: 1px solid $text;
      text-align: center;
      transition: .5s;
      &:hover{
        border: 1px solid $accent;
        background: $accent;
      }
    }
  }
  @media(min-width: 750px){
    &__intro{
      &--wrap{
        margin-top: 20px;
        div{
          position: static;
          width: 48%;
          height: 380px;
          &:last-child{
            position: static;
            margin-right: 10px;
            img{
              opacity: 1;
              width: 100%;
            }
          }
        }
      }
      &--title{
        text-align: left;
        margin-left: 10px;
        @include fontsize(30);
      }
      &--caption{
        color: $text;
      }
    }
  }
}
.about-title{
  background: url(../img/aboutTitle.jpg) right center /cover no-repeat;
}
.service-title{
  background: url(../img/aboutTitle.jpg) right center /cover no-repeat;
}
.performance-title{
  background: url(../img/aboutTitle.jpg) right center /cover no-repeat;
}
.flow-title{
  background: url(../img/aboutTitle.jpg) right center /cover no-repeat;
}

.reverse{
  flex-direction: row-reverse;
  div:last-child{
    margin-left: 10px;
    margin-right: 0;
  }
}
.bread-wrap{
  background: #3e2917;
}
.breadcrumb {
  margin: 0;
  padding: 0 10px 10px;
  @include max;
  li{
    display: inline-block;
    &:after{
      display: inline-block;
      margin: 0 10px;
      content: ">";
      color: #fff;
    }
    a{
      color: #fff;
    }
    &:last-child{
      &:after{
        content: "";
      }
    }
  }
}
.point{
  margin: 20px 0;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__box{
    display: block;
    box-shadow: 5px 5px 3px #dadada;
    width: 90%;
    margin: 0 auto 20px;
    img{
      width: 100%;
    }
    h3{
      margin: 0;
      @include fontsize(17);
      color: $text;
      margin: 0 10px;
    }
    p{
      margin: 0 10px 20px;
    }
  }
  @media(min-width: 650px){
    &__box{width: calc(50% - 5px);}
  }
  @media(min-width: 950px){
    &__box{width: calc(25% - 10px);}
  }
}
.howto{
  margin: 20px 0;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__box{
    display: block;
    text-align: center;
    width: 50%;
    margin-bottom: 20px;
    font-weight: bold;
    color: $main;
    svg{
      fill: $main;
      display: block;
      width: 100px;
      height: 100px;
      margin: 0 auto;
    }
  }
  @media(max-width: 349px){
    &__box{width: 100%;}
  }
  @media(min-width: 650px){
    &__box{width: calc(100% / 3);}
  }
  @media(min-width: 1050px){
    &__box{width: calc(100% / 6);}
  }
}
.merit{
  padding: 0;
  margin: 0 0 30px;
  &__box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    img{
      width: 100%;
    }
    div{
      width: 100%;
      &:last-child{
        padding: 0 10px;
      }
    }
    h3{
      margin: 0;
      line-height: 1.5;
    }
    p{
      margin: 0 0 30px;
    }
  }
  @media(min-width: 750px){
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    &__box{
      width: calc(50% - 10px);
    }
  }
}
.service-point{
  @media(min-width: 1150px){
    margin-bottom: 50px;
  }
}
.flow{
  @extend .point;
  margin-bottom: 0;
  &__box{
    @extend .point__box;
    position: relative;
    margin-bottom: 70px;
    &:after{
      content: "";
      position: absolute;
      bottom: -60px;
      left: calc(50% - 50px);
      border-right: 50px solid transparent;
      border-top: 50px solid $main;
      border-left: 50px solid transparent; 
    }
    &:last-child{
      margin-bottom: 0;
      &:after{
        display: none;
      }
    }
  }
  @media (min-width: 650px){
    &__box{
      width: calc(50% - 45px);
      margin: 0 50px 20px 0;
      &:after{
        transform: rotate(-90deg);
        left: auto;
        right: -100px;
        bottom: auto;
        top: calc(50% - 50px);
      }
      &:nth-child(even){
        margin: 0 0 20px;
        &:after{
          display: none;
        }  
      }
      &:nth-child(3),
      &:nth-child(4){
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }
  }
  @media (min-width: 1050px){
    justify-content: flex-start;
    &__box{
      width: calc(33% - 45px);
      margin: 0 70px 20px 0;
      &:after{
        right: -90px;
      }
      &:nth-child(even){
        margin: 0 70px 20px 0;
        &:after{
          display: block;
        }  
      }
      &:nth-child(3){
        margin: 0 0 20px;
        &:after{
          display: none;
        }  
      }
      &:nth-child(4),
      &:last-child{
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }
  }
}
.caution{
    background: #efbbbb;
    border-radius: 10px;
    padding: 10px;
    @media (min-width: 650px){
      padding: 20px;
    }
}
.provide {
  display: flex;
  padding: 0 10px;
  margin: 0;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  &__box {
    display: block;
    text-align: center;
    width: calc(50% - 5px);
    background: #3e2917;
    color: #fff;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 30px 10px;
    line-height: 1.2;
  }
}

.company-table{
  width: calc(100% - 20px);
  margin: 0 10px 30px;
  th{
    background: #f2f2f2;;
  }
  th,
  td{
    padding: 10px;
    border: 1px solid;
    display: block;
    width: 100%;
  }
  @media (min-width: 750px){
    th{
      width: 20%;
    }
    td{
      width: 80%;
    }
    th,
    td{
      display: table-cell;
    }
  }
}
.conditions{
  margin: 0 10px;
  h3{
    background: $text;
    color: #fff;
    padding-left: 10px;
    margin-bottom: 0;
  }
  ul,
  ol,
  dl,
  p{
    margin-top: 0;
  }
  dd {
    margin-left: 10px;
  }
}
//コンタクトフォーム
.contact_wrap{
    margin: 0 10px 20px;
    input[type="text"],input[type="email"]{
        width: 100%;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }
    p{
      width: 100%;
      margin: 10px 0;
    }
    .flex{
        margin-bottom: 0;
        @media(min-width:900px){
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: space-between;
            p{
                width: calc(50% - 20px);
            }
            input[type="text"],input[type="email"]{
                width: calc(100% - 110px);
                padding: 5px 10px;
            }
        }
    }
    label{
        display: inline-block;
        vertical-align: top;
        width: 100px;
        font-weight: bold;
        position: relative;
        padding-left: 15px;
        .require{
            color: red;
            margin-left: 5px;
        }
        &:before{
          content: "";
          background: #c19b5c;
          width: 10px;
          height: 80%;
          position: absolute;
          top: 10%;
          left: 0;
        }
    }
    .list{
        margin: 0 20px 0 5px;
    }
    input, button, textarea, select {
      border: 1px solid #333;
      &:focus{
        border: 1px solid $sub;
        outline: 0;  
      }
    }
    input{
        padding: 0 10px;
    }
    #exampleInputTitle,label.text_label{
        width: 100%;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }
    textarea{
        width: 100%;
        height: 200px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }
    button{
        width: 120px;
        background: transparent;
    }
    input[type="submit"],
    button[type="submit"]{
        background: transparent;
        margin-right: 10px;
        border: 1px solid #3f9e0e;;
        color: #3f9e0e;;
        -webkit-transition: .5s;;
        transition: .5s;
        &:hover{
            background: #3f9e0e;
            color: #fff;
        }
    }
    input[type="button"],
    button[type="reset"]{
        background: transparent;
        border: 1px solid red;
        color: red;
        -webkit-transition: .5s;
        transition: .5s;
        &:hover{
            background: red;
            color: #fff;
        }
    }
    input[type="submit"],
    input[type="button"]{
      padding: 5px 10px;
      margin-left: 10px;
    }
}

//サイトマップ
.sitemap{
  margin: 20px auto;
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  text-align: center;
  a{
    display: block;
    color: #fff;
    background: $main;
    padding: 10px;
    transition: .5s;
    &:hover{
      background: $accent;
    }
  }
  &__home{
    margin-right: 10px;
    width: calc(50% - 50px);
    position: relative;
    &:before{
      position: absolute;
      content: "";
      width: 2px;
      height: 436px;
      top: 50%;
      right: -50px;
      background: $main;
    }
    &:after{
      position: absolute;
      content: "";
      width: 100px;
      height: 2px;
      top: 50%;
      right: -100px;
      background: $main;
    }
  }
  &__low{
    padding: 0;
    margin: 0;
    width: calc(50% - 50px);
    li{
      display: block;
      margin-bottom: 10px;
      position: relative;
      &:after{
        position: absolute;
        content: "";
        width: 50px;
        height: 2px;
        top: 50%;
        left: -50px;
        background: $main;
      }
    }
  }
  @media(max-width: 749px){
    &__home{
      width: 100%;
      margin-bottom: 10px;
      &:before{
        height: 498px;
        top: 50%;
        left: 10px;
      }
      &:after{
        display: none;
      }
    }
    &__low{
      width: calc(100% - 10px);
      li{
        margin-left: 50px;
        &:after{
          width: 40px;
          top: 50%;
          left: -40px;
        }
      }
    }
  }
}
.formTable{
  @extend .company-table;
}
.thanks{
  text-align: center;
  h2{text-align: center;}
  a{
    display: block;
    color: $text;
    border: 1px solid;
    width: 50%;
    margin: 0 auto;
    padding: 10px;
    margin-top: 30px;
    transition: .5s;
    &:hover{
      background: $accent;
      color: #fff;
    }
  }
}